<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>New User</h2>
    <form [formGroup]="userForm" >
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-4">
          <label for="userType">User Type<span class="requried-field">*</span></label>
          <select
          id="userType"
          name="userType"
          class="form-control"
          formControlName="userType"
          [ngClass]="{ 'is-invalid': submitted && f['userType'].errors }"
        >
        <option value="" selected disabled>--Please select--</option>
          <option
            *ngFor="let data of userTypeData"
            [value]="data"
            >{{ data }}
          </option>
        </select>
          <div *ngIf="submitted && f['userType'].errors" class="invalid-feedback">
            <div *ngIf="f['userType'].errors['required']">user Type is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label for="userName"> User Name<span class="requried-field">*</span></label>
          <input
          type="text"
          id="userName"
          placeholder="Enter userName"
          class="form-control"
          formControlName="userName"
          [ngClass]="{ 'is-invalid': submitted && f['userName'].errors }"
        />
        <div *ngIf="submitted && f['userName'].errors" class="invalid-feedback">
            <div *ngIf="f['userName'].errors['required']">User Name is required</div>
            
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label for="userEmail">User Email</label>
          <input
            type="text"
            id="userEmail"
            placeholder="Enter userEmail"
            class="form-control"
            formControlName="userEmail"
            [ngClass]="{ 'is-invalid': submitted && f['userEmail'].errors }"
          />
          <div *ngIf="submitted && f['userEmail'].errors" class="invalid-feedback">
            <div *ngIf="f['userEmail'].errors['required']">Email is required</div>
            
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label for="userMobile">User Mobile<span class="requried-field">*</span></label>
          <input
            type="number"
            id="userMobile"
            placeholder="Enter userMobile"
            class="form-control"
            formControlName="userMobile"
            [ngClass]="{ 'is-invalid': submitted && f['userMobile'].errors }"
          />
          <div *ngIf="submitted && f['userMobile'].errors" class="invalid-feedback">
            <div *ngIf="f['userMobile'].errors['required']">userMobile is required</div>
          </div>
        </div>
  
        <div class="form-group col-sm-12 col-md-4">
          <label for="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Enter password"
            class="form-control"
            formControlName="password"
            [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
          />
          <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
            <div *ngIf="f['password'].errors['password']">Password is required</div>
            
          </div>
        </div>
        </div>
      <div class="formbutton">

      <button type="submit" class="btn btn-primary" (click)="onSubmit(userForm.value)">Submit</button>
      <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
    </div>
    </form>
  </div>
  