import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
  project: any = {};
  categoryData: string[] = [];
  userTypeData: string[] = ['Operator', 'User'];
  userForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  supplierName:string[]=[];
  keyword1="productName";
  productName:any[]=[];
  id:any;
  categoryName:any;
  supplier:any[]=[];
  product:any[]=[];
  keyword="supplierName";
  isEnabled=true;
  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router
  ) {}

  ngOnInit()  {
    this.userForm= this.formBuilder.group({
      userName: ['',Validators.required],
      userType: ['', Validators.required],
      userMobile: ['',Validators.required],
      userEmail: ['',Validators.required],
      password:['',Validators.required]
      
    });
  }

 

  goBack() {
    this.router.navigate(['/dashboard/user']);
  }
 
  get f() {
    return this.userForm.controls;
  }

  onSubmit(data: any) {
    if (this.userForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.postOn=moment().format()
    data.isActive = true;

    this.data.create(data).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/user'])
    })
  }
  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
    this.id = item.id
    this.userForm.patchValue({
      productName:item.productName,
      shortName:item.shortName
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getCategory().subscribe((val:any)=>{
      this.productName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      console.log(this.productName)
      return this.productName.filter(val => val.productName.toLowerCase().includes(search))
    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }

  onCancel(){
    this.userForm.reset()
  }
  selectEvent(item:any) {
    // do something with selected item
    console.log(item)
    this.categoryData=[];
   this.data.getCategory().subscribe((res:any)=>{
    this.product = Object.keys(res).map(key => ({ id: key, ...res[key] }));
    this.product.forEach((res:any)=>{
    if (res.supplierName && res.supplierName.toLowerCase().trim() === item.supplierName.toLowerCase().trim()) {
      this.categoryData.push(res.productName)
      console.log(this.categoryData)
    }
  })
   })
   
  
   
    this.userForm.patchValue({
     
      shortName:item.shortName,
      supplierName:item.supplierName
    })
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getCategory().subscribe((res:any)=>{
      this.supplierName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.supplierName)
        return this.supplierName.filter( val  =>   val.toLowerCase().includes(search));
    })    
  }

  onFocused(e:any) {
    console.log(e)
    // do something
  }

}
