<div class="container-fluid">
    <h3>Filters</h3>
      <div class="filters">
       
          <label for="supplier">User Name</label>
          <select id="supplier" class="dropdown">
              <!-- Add options here -->
          </select>
          <label for="category">User Type</label>
          
          <select id="category" class="dropdown">
              <!-- Add options here -->
          </select>
          <label for="location">Location</label>
         
          <select id="location" class="dropdown">
              <!-- Add options here -->
          </select>
          <button id="add-button" class="add-button" (click)="add()">Add +</button>
      </div>
      <div class="scroll">
        <table
    class="custom-table">
    <thead>
      <tr>
        
        <th>User Type</th>
        <th>Name</th>
        <th>Mobile No</th>
        <th>Mail Id</th>
        <th>Status</th>
        <th>Action</th>
        </tr>
        </thead>
        <tbody >
            <tr
            *ngFor="
            let element of userList
            | paginate: { itemsPerPage: 25, currentPage: page }
          "
            >
                
              <td>{{element.userType}}</td>
              <td>{{element.userName}}</td>
              <td>{{element.userMobile}}</td>
              <td>{{element.userEmail}}</td>
              <td>
                <div class="example-button-container">
                  <mat-icon
            class="status-icon"
            style="width: 30%;"
            [style.color]="element.isActive ? 'green' : 'red'"
            (click)="toggleStatus(element)"
          >
            {{ element.isActive ? 'toggle_on' : 'toggle_off' }}
          </mat-icon>
                </div>
              </td>  
            
            
              <td>
                
                <mat-icon (click)="onEdit(element.id)">edit</mat-icon>
                <mat-icon (click)="onDelete(element.id)" >delete</mat-icon>
              </td>
            </tr>
            </tbody>
            </table>
     </div>
  </div>